import React,{useState,useEffect,useRef} from 'react'
import { Form, Nav , Tab, Row, Col, InputGroup,Button ,Image } from 'react-bootstrap'

import Card from '../../components/defaultSnippets/Card';
import { Link, useParams,Navigate } from 'react-router-dom';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import avatars1 from '../../assets/images/avatars/01.png'
import avatars2 from '../../assets/images/avatars/avtar_1.png'
import avatars3 from '../../assets/images/avatars/avtar_2.png'
import avatars4 from '../../assets/images/avatars/avtar_3.png'
import avatars5 from '../../assets/images/avatars/avtar_4.png'
import avatars6 from '../../assets/images/avatars/avtar_5.png'
import { useSelector } from 'react-redux';
import { selectStudentStatus } from '../Students/studentSlice';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'; // Import Flatpickr CSS
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../api/axios'
import { useNavigate } from 'react-router-dom';
import {selectStatus} from '../login/loginSlice'
 import { checkPermission } from '../../components/Snippets/utils'
 import { redirect } from 'react-router-dom';
const StaffEdit = () => {
    const {id}=useParams()
     const permissions=useSelector(selectStatus)
     const [userPermissions, setUserPermissions] = useState(permissions.user.data.permissions);
//alert(id);
const[isLoading, setIsLoading]=useState(true);
const [formData, setFormData] = useState({});
const [citiesForState, setCitiesForState] = useState([]);
const[imagePreview,setImagePreview]=useState('');
const studentData=useSelector(selectStudentStatus);
const [selectedYear, setSelectedYear] = useState('');
  const[duration,setDuration] = useState('');
const fileInputRef = useRef(null);

const fetchCourse = async () => {
    try {
        const res = await axios.get('/api/staff/' + id);
        setIsLoading(false);
        setFormData(res.data.staff);
        if (res.data.staff && res.data.staff.profilePic) {
            // Set the imagePreview state with the absolute URL of the profile picture
            const imagePath = process.env.REACT_APP_API_BASE_URL+'/'+res.data.staff.profilePic;
            setImagePreview(imagePath);
        }
        // Pre-select state and city based on the fetched data
        handleStateChange({ target: { value: res.data.staff.state } });
      //   handleyearChange({ target: { value: res.data.student.academic_year } });
    } catch (err) {
        console.log(err.response);
    }
};

 

useEffect(() => {
    // Wait for the state to be updated before calling handleCityChange
    fetchCourse()
   if (formData.state && studentData.citynames.data.results.length > 0) {
        const cities = studentData.citynames.data.results.filter(
            (city) => city.state_id === formData.state
        );
        setCitiesForState(cities);
      //   setFormData(prevFormData => ({
      //       ...prevFormData,
      //       city: formData.city // Pre-select city based on fetched data
      //   }));
    }
}, [studentData.citynames.data.results]);
 

  const navigate = useNavigate();

  const handleStateChange = (event) => {
    const selectedStateId = parseInt(event.target.value); // Convert to number
    console.log("Selected State:", selectedStateId);
    setFormData(prevFormData => ({ ...prevFormData, state: selectedStateId }));
    // Filter cities based on the selected state ID
    const cities = studentData.citynames.data.results.filter(
        (city) => city.state_id === selectedStateId
    );
    console.log("Cities for State:", cities);
    setCitiesForState(cities);
};


  const handleCityChange = (event) => {
    const selectedCityId = parseInt(event.target.value);
    setFormData({ ...formData, city: selectedCityId }); // Update formData.city
  };

   const handleCourseChange = (event) => {
    const selectedCourseId = parseInt(event.target.value);
    setFormData({ ...formData, current_program_handled: selectedCourseId }); // Update formData.city
   // Find the course object with the selectedCourseId
   console.log(selectedCourseId);
   console.log(studentData.coursenames.data.results);
  const selectedCourse = studentData.coursenames.data.results.find(course => course.id === selectedCourseId);
  console.log(selectedCourse);
  if (selectedCourse) {
    // Extract the course_duration from the selected course
    const courseDuration = selectedCourse.course_duration;
    // Now you can do whatever you want with the courseDuration, such as setting it in the state
    console.log(courseDuration);
    setDuration(courseDuration);
  }
  };

  // Function to generate options for past four years
const generateYearOptions = () => {
   let temp;
   if(duration===''){
       temp=4;
   }
   else{
       temp=duration;
   }
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let i = 0; i <= currentYear - 2020; i++) {
    const startYear = currentYear - i;
    const endYear = startYear + parseInt(temp);
    years.push(`${startYear}-${endYear}`);
  }
  return years.map((year) => (
    <option key={year} value={year}>
      {year}
    </option>
  ));
};
  const handleDateChange = (selectedDates, dateField) => {
    // const selectedDate = selectedDates[0];
    // setFormData({
    //   ...formData,
    //   [dateField]: selectedDate,
    // });
    const selectedDate = selectedDates[0];
    let formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-GB') : ''; // Format the date as "d/m/Y"
    formattedDate = formattedDate.replace(/\//g, '-'); // Replace "/" with "-"
    setFormData({
        ...formData,
        [dateField]: formattedDate, // Set the formatted date in the formData
    });
  };

  

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     setFormData({
//       ...formData,
//       profilePic: file, // Set profilePic in formData to the selected file
//     });
//     console.log(formData);
//   };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
        ...formData,
        profilePic: file, // Set profilePic in formData to the selected file
    });

    // Show image preview
    const reader = new FileReader();
    reader.onload = (event) => {
        // Set the preview image source
        setImagePreview(event.target.result);
    };
    reader.readAsDataURL(file); // Read the selected file as a data URL
    console.log(formData);
};

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
    if(checked==true)
    {
      const inpPassword = document.getElementById('password');
      inpPassword.setAttribute('required', true);
    }
    else
    {
      const inpPassword = document.getElementById('password');
      inpPassword.removeAttribute('required');
    }
    
  };


  const handleHasRoleChange = (e) => {
   const { name, value } = e.target;
   if(value==0)
   {
      const RoleDropdwn = document.getElementById('current_role');
      const inpPassword = document.getElementById('password');
      RoleDropdwn.value='';
      inpPassword.value='';
      RoleDropdwn.disabled = true;
      inpPassword.disabled=true;
      setFormData({
         ...formData,
         current_role: '', // Set current_role to empty
         password: '',     // Set password to empty
         [name]: value,
      });
   }
   else
   {
      const RoleDropdwn = document.getElementById('current_role');
      const inpPassword = document.getElementById('password');
      RoleDropdwn.disabled = false;
      inpPassword.disabled=false;
      RoleDropdwn.setAttribute('required', true); // Add 'required' attribute
      inpPassword.setAttribute('required', true);
      setFormData({
         ...formData,
         [name]: value,
       });
   }
   
 };
   const handleyearChange = (e) => {
    setSelectedYear(e.target.value);
    setFormData(prevFormData => ({
            ...prevFormData,
            academic_year: e.target.value // Pre-select city based on fetched data
        }));
  };


  const handleUpdate = (e) => {
    e.preventDefault();
console.log(formData);
//return false;
     const formDataToSend = new FormData();

    // Append all form data fields to formDataToSend
    Object.entries(formData).forEach(([key, value]) => {
        formDataToSend.append(key, value);
    });

    // Append the profilePic file to formDataToSend
    formDataToSend.append('profilePic', formData.profilePic);
    formDataToSend.append('_method','PUT');
    console.log("finaldata=");
    console.log(formDataToSend,"tst");
  

//     const formDataToSend = new FormData();
// console.log(formData)
//     // Append all form data fields to formDataToSend
//     Object.entries(formData).forEach(([key, value]) => {
//         formDataToSend.append(key, value);
//     });
//     console.log(formDataToSend);
//     // Append the profilePic file to formDataToSend
//     //formDataToSend.append('profilePic', formData.profilePic);

//     console.log(formDataToSend);
   //  for (const entry of formDataToSend.entries()) {
   //      console.log(entry);
   //  }
   //    return false;
    console.log(formDataToSend);
    SendData(formDataToSend);
    // Handle form submission with formData
  };

  const SendData = async(formDataToSend) =>{
     const loaderid =  toast.loading("Updating data...",{
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
         });
  try {
   
        const resupdate = await axios.post(`/api/staffupdate/${id}`, formDataToSend,{
          headers: {
            'Content-Type':'multipart/form-data'
          }
        });

        // If request is successful
        console.log(resupdate.data);
        toast.update(loaderid, { render: "Successful", type: "success", isLoading: false, autoClose: 1000, theme: "light" });
        //navigate('/coursemanagement');
    } catch (err) {
        // If request fails
        console.log(err.response);
        if (err.response && err.response.status === 422) {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        } else {
            toast.update(loaderid, { render: err.response.data.message, type: "error", isLoading: false, autoClose: 3000, theme: "colored" });
        }
    }
  }


  return (

    <div>
       {checkPermission(userPermissions, 'edit-staff') ? (<>
         <ToastContainer />
         <Row>
               <Col xl="3" lg="4" className="">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Edit Staf</h4>
                        </div>
                     </Card.Header>
                     <Card.Body className="text-start">
                        <Form >
                           <Form.Group className="form-group">
                           <div className="profile-img-edit position-relative">
    {imagePreview ? (
        <Image src={imagePreview} alt="profile-preview" className="profile-pic rounded avatar-100" />
    ) : (
        <>
            <Image className="theme-color-default-img profile-pic rounded avatar-100" src={avatars1} alt="profile-pic"/>
            <Image className="theme-color-purple-img profile-pic rounded avatar-100" src={avatars2} alt="profile-pic"/>
            <Image className="theme-color-blue-img profile-pic rounded avatar-100" src={avatars3} alt="profile-pic"/>
            <Image className="theme-color-green-img profile-pic rounded avatar-100" src={avatars5} alt="profile-pic"/>
            <Image className="theme-color-yellow-img profile-pic rounded avatar-100" src={avatars6} alt="profile-pic"/>
            <Image className="theme-color-pink-img profile-pic rounded avatar-100" src={avatars4} alt="profile-pic"/>
        </>
    )}
    <div className="upload-icone bg-primary">
        <label htmlFor="file-upload" style={{cursor:'pointer'}}>
            <svg className="upload-button" width="14" height="14" viewBox="0 0 24 24">
                <path fill="#ffffff" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
            </svg>
        </label>
        <Form.Control
            id="file-upload"
            className="file-upload"
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={handleFileChange}
        />
    </div>
</div>

                              <div className="img-extension mt-3">
                                 <div className="d-inline-block align-items-center">
                                    <span>Only</span>{' '}
                                    <Link to="#">.jpg</Link>{' '}
                                    <Link to="#">.png</Link>{' '}
                                    <Link to="#">.jpeg</Link>{' '}
                                    <span>allowed</span>
                                 </div>
                              </div>
                           </Form.Group>
                            <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="staff_id_number">Staff ID Number (AICTE):</Form.Label>
                                    <Form.Control type="text"  id="staff_id_number" name="staff_id_number" placeholder="" value={formData.staff_id_number} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="aadhar_id">Aadhar Number:</Form.Label>
                                    <Form.Control type="text"  id="aadhar_id" name="aadhar_id" placeholder="" value={formData.aadhar_id} onChange={handleChange}/>
                                 </Form.Group>
                                   <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="pan_id">PAN Number:</Form.Label>
                                    <Form.Control type="text"  id="pan_id" name="pan_id" placeholder="" value={formData.pan_id} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="lname">Date of Birth:</Form.Label>
                                    {/* <Form.Control type="text"  id="date_of_birth" name="date_of_birth" placeholder="Last Name" value={formData.date_of_birth} onChange={handleChange}/> */}
                                    <Flatpickr
          id="date_of_birth"
          name="date_of_birth"
          value={formData.date_of_birth}
          onChange={(selectedDates) =>
            handleDateChange(selectedDates, 'date_of_birth')
          }
          options={{
            dateFormat: 'd-m-Y', // Customize date format
            altInput: true, // Enable alternate input
            altFormat: 'd-m-Y', // Alternate input format
          }}
          className="form-control" // Add any additional CSS classes here
        />
                                 </Form.Group>
                                 
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="current_program_handled">Course handled:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_program_handled"
                                        id="current_program_handled"
                                        className="selectpicker form-control"
                                        value={formData.current_program_handled}
                                        onChange={handleCourseChange}
                                    >
                                        <option>Select Course</option>
                                        {studentData.coursenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.coursename}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="admission_date">Joining Date:</Form.Label>
                                    {/* <Form.Control type="text"  id="admission_date" name="admission_date" placeholder="" value={formData.admission_date} onChange={handleChange}/> */}
                                    <Flatpickr
                                       id="joining_date"
                                       name="joining_date"
                                       value={formData.joining_date}
                                       onChange={(selectedDates) =>
                                          handleDateChange(selectedDates, 'joining_date')
                                       }
                                       options={{
                                          dateFormat: 'd-m-Y', // Customize date format
                                          altInput: true, // Enable alternate input
                                          altFormat: 'd-m-Y', // Alternate input format
                                          maxDate: new Date(), // Disable future dates
                                       }}
                                       className="form-control" // Add any additional CSS classes here
                                    />

                                 </Form.Group>
                                 <Form.Group className="col-md-12 form-group">
                                                                <Form.Label htmlFor="releiving_date">Releiving Date:</Form.Label>
                                                                {/* <Form.Control type="text"  id="graduation_date" name="graduation_date" placeholder="" value={formData.graduation_date} onChange={handleChange}/> */}
                                                                <Flatpickr
                                    id="releiving_date"
                                    name="releiving_date"
                                    value={formData.releiving_date}
                                    onChange={(selectedDates) =>
                                        handleDateChange(selectedDates, 'releiving_date')
                                    }
                                    options={{
                                        dateFormat: 'd-m-Y', // Customize date format
                                        altInput: true, // Enable alternate input
                                        altFormat: 'd-m-Y', // Alternate input format
                                    }}
                                    className="form-control" // Add any additional CSS classes here
                                    />
                                                            </Form.Group>
                                 
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
               <Col xl="9" lg="8">
                  <Card>
                     <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                           <h4 className="card-title">Edit Staff Information</h4>
                        </div>
                     </Card.Header>
                     <Card.Body  className="text-start">
                        <div className="new-user-info">
                            <Form className="form-horizontal" onSubmit={(e)=>handleUpdate(e)}>
                              <div className="row">
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="first_name">First Name:</Form.Label>
                                    <Form.Control type="text"  id="first_name" name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="last_name">Last Name:</Form.Label>
                                    <Form.Control type="text"  id="last_name" name="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label>Gender:</Form.Label>
                                    <select name="gender" id="gender" className="selectpicker form-control" data-style="py-0" value={formData.gender} onChange={handleChange}>
                                       <option>Select</option>
                                       <option>Male</option>
                                       <option>Female</option>
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="previous_education">Designation:</Form.Label>
                                    <Form.Control type="text"  id="designation" name="designation" placeholder="" value={formData.designation} onChange={handleChange}/>
                                 </Form.Group>
                                 
                                 <Form.Group className="col-md-12 form-group">
                                    <Form.Label htmlFor="address">Address:</Form.Label>
                                    <Form.Control type="text"  id="address" name="address" placeholder=""  value={formData.address} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-4 form-group">
                                    <Form.Label>State:</Form.Label>
                                    <select
                                        name="state"
                                        id="state"
                                        className="selectpicker form-control"
                                        value={formData.state}
                                        onChange={handleStateChange}
                                    >
                                        <option>Select State</option>
                                        {studentData.statenames.data.results.map((state, index) => (
                                        <option key={index} value={state.id}>
                                            {state.name}
                                        </option>
                                        ))}
                                    </select>
                                    </Form.Group>

                                    <Form.Group className="col-md-4 form-group">
                                    <Form.Label>City:</Form.Label>
                                    <select
                                        name="city"
                                        id="city"
                                        className="selectpicker form-control"
                                        value={formData.city}
                                        onChange={handleCityChange}
                                    >
                                        <option value=''>Select City</option>
                                        {citiesForState.map((city, index) => (
                                        <option key={index} value={city.id}>
                                            {city.city}
                                        </option>
                                        ))}
                                    </select>
                                    </Form.Group>

                                 <Form.Group className="col-md-4 form-group">
                                    <Form.Label htmlFor="add1">Postal Code:</Form.Label>
                                    <Form.Control type="text"  id="postal_code" name="postal_code" placeholder=""  value={formData.postal_code} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label htmlFor="add1">Email:</Form.Label>
                                    <Form.Control type="email"  id="email" name="email" placeholder=""  value={formData.email} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                                    <Form.Control type="text"  id="phone_number" name="phone_number" placeholder=""  value={formData.phone_number} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="academic_experience">Academic Experience:</Form.Label>
                                    <Form.Control type="text"  id="academic_experience" name="academic_experience" placeholder=""  value={formData.academic_experience} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="academic_experience">Qualification:</Form.Label>
                                    <Form.Control type="text"  id="qualification" name="qualification" placeholder=""  value={formData.qualification} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6  form-group">
                                    <Form.Label htmlFor="email">Nationality:</Form.Label>
                                    <Form.Control type="text"  id="nationality" name="nationality" placeholder=""  value={formData.nationality} onChange={handleChange}/>
                                 </Form.Group>
                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label>Caste:</Form.Label>
                                    <select name="caste" id="caste" className="selectpicker form-control" data-style="py-0"  value={formData.caste} onChange={handleChange}>
                                       <option value="">Select Caste</option>
                                       <option value="SC">SC</option>
                                       <option value="ST">ST</option>
                                       <option value="MBC">MBC</option>
                                       <option value="OBC">OBC</option>
                                    </select>
                                 </Form.Group>

                                 <Form.Group className="col-md-6 form-group">
                                    <Form.Label>Approval Status:</Form.Label>
                                    <select name="approval_status" id="approval_status" className="selectpicker form-control" data-style="py-0"  value={formData.approval_status} onChange={handleChange}>
                                       <option value="">Select Status</option>
                                       <option value="Pending">Pending</option>
                                       <option value="Approved">Approved</option>
                                       <option value="Rejected">Rejected</option>
                                    </select>
                                 </Form.Group>
                                 <div className="header-title">
                                 <h4 className="card-title">Access Information</h4>
                              </div>
                                 <Form.Group className="col-md-4 form-group">
                                    <Form.Label>Has Portal Access:</Form.Label>
                                    <select name="hasportalaccess" id="hasportalaccess" className="selectpicker form-control" data-style="py-0"  value={formData.hasportalaccess} onChange={handleHasRoleChange}>
                                       <option value="">Select Status</option>
                                       <option value="1">Yes</option> 
                                       <option value="0">No</option>
                                    </select>
                                 </Form.Group>
                               <Form.Group className="col-md-4 form-group">
                                    <Form.Label htmlFor="current_role">Role:</Form.Label>
                                    {/* <Form.Control type="text"  id="current_program" name="current_program" placeholder="" value={formData.current_program} onChange={handleChange}/> */}
                                     <select
                                        name="current_role"
                                        id="current_role"
                                        className="selectpicker form-control"
                                        value={formData.current_role}
                                        onChange={handleHasRoleChange}
                                    >
                                        <option value=''>Select Role</option>
                                        {studentData.rolenames.data.results.map((course, index) => (
                                        <option key={index} value={course.id}>
                                            {course.name}
                                        </option>
                                        ))}
                                    </select>
                                 </Form.Group>
                                 <Form.Group className="col-md-4  form-group">
                                    <Form.Label htmlFor="email">Update Password: <input type="checkbox" name="update_password" onChange={handleCheckboxChange}/></Form.Label>
                                    <Form.Control type="password"  id="password" name="password" placeholder=""  value={formData.password} onChange={handleHasRoleChange}/>
                                 </Form.Group>
                                  
                                    
                              </div>
                             
                              <Form.Group className="form-group text-end">
                                    <Button type="submit" variant="btn btn-primary">Update</Button>{' '}
                                     <Link to={'/staffmanagement'}><Button type="button" variant="btn btn-danger">cancel</Button></Link>
                                </Form.Group>
                             
                            </Form>
                        </div>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
            </>
       ):(
       <><Navigate to="/staffmanagement"/></>
       )}
            </div>
  );
}

export default StaffEdit;
