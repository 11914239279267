import React from 'react';
import { Document, Page, View, StyleSheet, Text, Image, Font } from '@react-pdf/renderer';
import { FaWeight } from 'react-icons/fa';
import notoSansRegular from '../../assets/fonts/NotoSans-Regular.ttf';
import logoImage from '../../assets/images/auth/1456126557logo.png'

// Register the font
Font.register({ family: 'Noto Sans', src: notoSansRegular });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: '15px',
    fontFamily: 'Noto Sans',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  logo: {
    width: '60px',
    height: '60px',
    marginBottom: 0,
  },
  table: {
    width: '100%',
    border: '0px solid black',
    borderCollapse: 'collapse',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    padding: 0,
    border: '0px solid black', // Adjust border style as needed
    textAlign: 'center',
  },
  boldStyleHeader: {
    fontWeight: 'bold',
    fontSize: '22px',
  },
   tableCellHeader: {
    padding: 0,
    border: '0px solid black', // Adjust border style as needed
    textAlign: 'center',
  },
   tableCellThirdHeader: {
    padding: 0,
    border: '0px solid black', // Adjust border style as needed
    textAlign: 'center',
    fontSize:'10px'
  },tableCellSecondHeader: {
    padding: 0,
    border: '0px solid black', // Adjust border style as needed
    textAlign: 'center',
    fontSize:'12px'
  },
  tableCellLeft:{
    textAlign: 'left',
    marginLeft:'10px',
    border:'0px solid black'
  },
  documentcellStyle:{
    fontSize:'13px',
    marginTop:'10px'  
  },
  tctext:{
    textAlign: 'center',
     fontWeight: 'bold',
    fontSize: '16px',
    marginTop:'20px',
    border: '0px solid black', // Adjust border style as needed
  },
  bgColor: {
    backgroundColor: 'lightgray',
  }
  
});

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertDateOfBirthToWords(dateOfBirth) {
  const months = [
    "zero", "one", "two", "three", "four", "five", "six",
    "seven", "eight", "nine", "ten", "eleven", "twelve"
  ];

  const dateParts = dateOfBirth.split('-');
  const day = parseInt(dateParts[0], 10);
  const month = capitalizeFirstLetter(months[parseInt(dateParts[1], 10)]);
  const year = dateParts[2];

  const numbers = [
    "zero", "one", "two", "three", "four", "five", "six",
    "seven", "eight", "nine", "ten", "eleven", "twelve",
    "thirteen", "fourteen", "fifteen", "sixteen", "seventeen",
    "eighteen", "nineteen"
  ];

  const tens = [
    "", "", "twenty", "thirty", "forty", "fifty", "sixty",
    "seventy", "eighty", "ninety"
  ];

  let result = "";

  if (day <= 19) {
    result += capitalizeFirstLetter(numbers[day]);
  } else {
    result += capitalizeFirstLetter(tens[Math.floor(day / 10)]);
    if (day % 10 !== 0) {
      result += " " + capitalizeFirstLetter(numbers[day % 10]);
    }
  }

  result += ` ${month} ${capitalizeFirstLetter(numbers[year[0]])} ${capitalizeFirstLetter(numbers[year[1]])} ${capitalizeFirstLetter(numbers[year[2]])} ${capitalizeFirstLetter(numbers[year[3]])}`;

  return result;
}

const MyDocument = ({ postfromData }) => {
  const dateParts = postfromData.tcdata.created_at.split('T');
  const dateOnly = dateParts[0];
  const dateOfBirth = postfromData.tcdata.tc_dob;
const dateInWords = convertDateOfBirthToWords(dateOfBirth);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Image src={logoImage} style={styles.logo} /> {/* Logo in the first column */}
              <View style={{ flexDirection: 'column', flex: 1 }}> {/* Second column */}
                <Text style={[styles.tableCellHeader, styles.boldStyleHeader]}>A.R. ENGINEERING COLLEGE</Text> {/* First row of the second column */}
                <Text style={[styles.tableCellSecondHeader]}>Vadakuchipalayam, Kappiyampuliyur Post, VILLUPURAM - 605 601.</Text> {/* Second row of the second column */}
                <Text style={[styles.tableCellThirdHeader]}>(Approved by AICTE & Affilicated to Anna University Chennai)</Text> {/* Second row of the second column */}
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Register No.</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCell,styles.documentcellStyle,{marginLeft:'55%'}]}>TC No. &nbsp;&nbsp;{postfromData.tcdata.tc_number}</Text> {/* Second column of the second row */}
                </View>
              </View>
            </View>
           <View style={styles.tableRow}>
              <View style={[styles.borderBox, { flexDirection: 'row', flex: 1 }]}>
   
                <Text style={[styles.tableCell, styles.tctext, { marginLeft:'35%',border:'1px solid black',borderRadius:'5px' ,padding:'4px'}]}>
                TRANSFER CERTIFICATE
              </Text>
            </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Name of the Student</Text> {/* First column of the second row */}
                </View>
                <View >
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_studname}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Name of the parent/Guardian</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_guardian}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
           <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Nationality & Religion</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_natrel}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
           <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Community & Caste</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_caste}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Date of Birth as entered in the Admission Register (in Figures and Words)</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_dob}{'\n'}({dateInWords})</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Gender</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_gender}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Date of Admission</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_doa}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Course and Year to which the student was admitted</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.studentdata[0].coursename}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Course in which the student was studying at the time of leaving</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.studentdata[0].coursename}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Whether qualified for promotion to Higher Class</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_qualified}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Reason for leaving the Institution</Text> {/* First column of the second row */}
                </View>
                 <View >
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_reason}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Date on which the student actually left the Institution</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_releivingdate}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Date of issue of Transfer Certificate</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_date}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>Student's Conduct and Character</Text> {/* First column of the second row */}
                </View>
                 <View>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{width:'2px'}]}>:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle]}>{postfromData.tcdata.tc_conduct}</Text> {/* Second column of the second row */}
                </View>
              </View>
          </View>
          <View style={styles.tableRow}>
             <Text style={[styles.tableCellLeft,styles.documentcellStyle,{marginTop:'40px'}]}>Date&nbsp;&nbsp;:</Text> {/* First row of the second column */}
          </View>
          <View style={styles.tableRow}>
              <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCellLeft,styles.documentcellStyle,{marginTop:'30px'}]}>Seal&nbsp;&nbsp;&nbsp;:</Text> {/* First column of the second row */}
                </View>
                <View style={{ flex: 1 }}>
                  <Text style={[styles.tableCell,styles.documentcellStyle,{marginLeft:'5%',marginTop:'30px'}]}>PRINCIPAL</Text> {/* Second column of the second row */}
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
