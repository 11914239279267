import React from 'react';
import { Document, Page, View, StyleSheet, Text, Font } from '@react-pdf/renderer';
import { FaWeight } from 'react-icons/fa';
import notoSansRegular from '../../assets/fonts/NotoSans-Regular.ttf'

// Register the font
Font.register({ family: 'Noto Sans', src: notoSansRegular });
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    fontSize: '15px',
    fontFamily: 'Noto Sans',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    
  },
  table: {
    width: '100%',
    border: '1px solid black',
    borderCollapse: 'collapse',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    padding: 5,
    border: '0px solid black',
    width:'100%',
    textAlign: 'center',
  },
  leftAlign: {
    textAlign: 'left',
  },
  rightAlign: {
    textAlign: 'right',
  },
  bolstyle:{
    fontWeight:'bold',
    fontSize:'22px'
  },
  bolstyle2:{
    fontWeight:'bold',
    fontSize:'14.4px'
  },
  paddingCustomLeft:{
    paddingLeft: 155,
  },
  paddingCustomLeft1:{
    paddingLeft: 60,
  },
  borderRightCustom:{
    borderRight: '1px solid black',
  },
   smallText: {
    width: 50, // Adjust the width as needed
  },
  smallpaddingCustomLeft:{
    paddingLeft: 15,
  },
  custommargintop:{
    marginTop: 40,
  }
});

const MyDocument = ({ postfromData }) => {
    const dateParts = postfromData.created_at.split('T');
  const dateOnly = dateParts[0];
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell,styles.bolstyle]}>A.R. ENGINEERING COLLEGE</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell,styles.bolstyle2]}>VADAKUCHIPALAYAM, VILLUPURAM – 605601</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>No: {postfromData.invoiceno}</Text>
              <Text style={[styles.tableCell, styles.rightAlign]}>Date: {dateOnly}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Name: {postfromData.invoicetoname}</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.paddingCustomLeft]}>Year: {postfromData.invoiceyear}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Branch: </Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>On account of:</Text>
            </View>
            <View style={{ borderBottom: '1px solid black', width: '100%', marginBottom: 5 }} /> {/* Horizontal line */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Admission Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.admissionfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Tution Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.tutionfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Special Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.specialfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Laboratory Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.labfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Library Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.libraryfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Stationary Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.stationaryfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Physical Education Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.physedfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Other Facilities Fee (Including)</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.otherfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Machineries Depm. Misc.etc</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.machineriesfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Computer Science Lab Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.compfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Examination Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.examfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Penalty</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.penaltyfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Certificate Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.certfee}</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Miscellaneous Fee</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.miscfee}</Text>
            </View>
             <View style={{ borderBottom: '1px solid black', width: '100%', marginBottom: 5 }} /> {/* Horizontal line */}
              <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.leftAlign]}>Grand Total</Text>
              <Text style={[styles.tableCell, styles.smallText]}>-</Text>
              <Text style={[styles.tableCell, styles.leftAlign, styles.smallpaddingCustomLeft]}>₹&nbsp;{postfromData.totalamount}</Text>
            </View>
          </View>
           <View style={styles.table}>
                <View style={[styles.tableRow, styles.custommargintop]}>
                    <Text style={[styles.tableCell,styles.bolstyle2,styles.leftAlign,styles.paddingCustomLeft1]}>Cashier</Text>
                    <Text style={[styles.tableCell,styles.bolstyle2,styles.leftAlign,styles.paddingCustomLeft]}>Principal</Text>
                </View>
           </View>
        </View>
      </Page>
    </Document>
  );
};

export default MyDocument;
