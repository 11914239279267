import React from 'react'
import App from '../App'
import MainLayout from '../views/layouts/MainLayout'
import Dashboard from '../views/dashboard/Dashboard'

import LandingLayout from '../views/Courses/LandingLayout'
import LandingMain from '../views/Courses/LandingMain'
import CourseAdd from '../views/Courses/CourseAdd'
import CourseEdit from '../views/Courses/CourseEdit'

import StudentLandingLayout from '../views/Students/StudentLandingLayout'
import StudentLandingMain from '../views/Students/StudentLandingMain'
import StudentAdd from '../views/Students/StudentAdd'
import StudentEdit from '../views/Students/StudentEdit'

import StaffLandingLayout from '../views/Staffs/StaffLandingLayout'
import StaffLandingMain from '../views/Staffs/StaffLandingMain'
import StaffAdd from '../views/Staffs/StaffAdd'
import StaffEdit from '../views/Staffs/StaffEdit'

import BillingLandingLayout from '../views/Billing/BillingLandingLayout'
import BillingLandingMain from '../views/Billing/BillingLandingMain'
import BillingAdd from '../views/Billing/BillingAdd'
import BillingEdit from '../views/Billing/BillingEdit'
import BillingView from '../views/Billing/BillingView'
import BillingPrint from '../views/Billing/BillingPrint'

import FeesLandingLayout from '../views/Fees/FeesLandingLayout'
import FeesLandingMain from '../views/Fees/FeesLandingMain'
import FeesAdd from '../views/Fees/FeesAdd'
import FeesEdit from '../views/Fees/FeesEdit'

import RolesLandingLayout from '../views/Roles/LandingLayout'
import RolesLandingMain from '../views/Roles/LandingMain'
import RoleAdd from '../views/Roles/RoleAdd'
import RoleEdit from '../views/Roles/RoleEdit'

import AttendanceLandingLayout from '../views/Attendance/AttendanceLandingLayout'
import AttendanceLandingMain from '../views/Attendance/AttendanceLandingMain'
import AttendanceAdd from '../views/Attendance/AttendanceAdd'
import AttendanceEdit from '../views/Attendance/AttendanceEdit'
import AttendanceReport from '../views/Attendance/AttendanceReport'

import TranscertLandingLayout from '../views/TransferCert/TranscertLandingLayout'
import TranscertLandingMain from '../views/TransferCert/TranscertLandingMain'
import TranscertAdd from '../views/TransferCert/TranscertAdd'
import TranscertEdit from '../views/TransferCert/TranscertEdit'
import TranscertPrint from '../views/TransferCert/TranscertPrint'

import SubjectsLandingLayout from '../views/Subjects/SubjectsLandingLayout'
import SubjectsLandingMain from '../views/Subjects/SubjectsLandingMain'
import SubjectsAdd from '../views/Subjects/SubjectsAdd'
import SubjectsEdit from '../views/Subjects/SubjectsEdit'

import StudPerformanceLandingLayout from '../views/StudentPerformance/StudPerformanceLandingLayout'
import StudPerformanceLandingMain from '../views/StudentPerformance/StudPerformanceLandingMain'
import StudPerformanceAdd from '../views/StudentPerformance/StudPerformanceAdd'
import StudPerformanceEdit from '../views/StudentPerformance/StudPerformanceEdit'
export const CustomRouter = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard valueFromRoute="DASHBOARD" valueFromSubroute=""/>
            },
            {
                path: "coursemanagement",
                exact: true,
                element:<LandingLayout/>,
                children: [
                {
                    path: "", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <LandingMain/>, // Replace with the appropriate component or content
                },
                {
                    path: "addcourse", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <CourseAdd/>, // Replace with the appropriate component or content
                }
                ,
                {
                    path: "editcourse/:id", // Empty path for the "hipaa" route itself
                    exact: true,
                    element: <CourseEdit/>, // Replace with the appropriate component or content
                }
                
               
                ]
            },
            {
                path: "studentmanagement",
                exact: true,
                element:<StudentLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudentLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addstudent", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudentAdd/>, // Replace with the appropriate component or content
                    }
                    ,
                    {
                        path: "editstudent/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudentEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "staffmanagement",
                exact: true,
                element:<StaffLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addstaff", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editstaff/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StaffEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "billing",
                exact: true,
                element:<BillingLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addbilling", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editbilling/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "viewbilling", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingView/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "printbilling/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <BillingPrint/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "feesmanagement",
                exact: true,
                element:<FeesLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <FeesLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addfee", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <FeesAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editfee/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <FeesEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "rolemanagement",
                exact: true,
                element:<RolesLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <RolesLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addrole", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <RoleAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editrole/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <RoleEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "studentattendance",
                exact: true,
                element:<AttendanceLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addattendance", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editattendance/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "attendancereport", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <AttendanceReport/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "transfercertificate",
                exact: true,
                element:<TranscertLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addtranscert", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "edittranscert/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertEdit/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "printtranscert/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <TranscertPrint/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "subjectmanagment",
                exact: true,
                element:<SubjectsLandingLayout/>,
                 children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <SubjectsLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addsubject", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <SubjectsAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editsubject/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <SubjectsEdit/>, // Replace with the appropriate component or content
                    }
                ]
            },
            {
                path: "studentperformance",
                exact: true,
                element:<StudPerformanceLandingLayout/>,
                children: [
                    {
                        path: "", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudPerformanceLandingMain/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "addperformance", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudPerformanceAdd/>, // Replace with the appropriate component or content
                    },
                    {
                        path: "editperformance/:id", // Empty path for the "hipaa" route itself
                        exact: true,
                        element: <StudPerformanceEdit/>, // Replace with the appropriate component or content
                    }
                ]
            }
        ]
       
    }
]

