
import React from 'react'

import SubHeaderL from '../layouts/SubHeaderL';
import SwiperE from '../../components/Snippets/SwiperE';
import { persistor } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getStateNames,getCityNames,getCourseNames,getRoleNames } from '../Students/studentSlice';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { selectStudentStatus } from '../Students/studentSlice';

const Dashboard = ({ valueFromRoute,valueFromSubroute }) => {
  const dispatch=useDispatch()
  const statenames=useSelector(selectStudentStatus);
  useEffect(()=>{
 
 if (statenames && statenames.statenames && statenames.statenames.data && statenames.statenames.data.results) {
  console.log("Existing data");
  console.log(statenames.statenames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching...");
  fetchStatenames();
}

if (statenames && statenames.citynames && statenames.citynames.data && statenames.citynames.data.results) {
  console.log("Existing data cities");
  console.log(statenames.citynames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching cities...");
  fetchCitynames();
}

if (statenames && statenames.coursenames && statenames.coursenames.data && statenames.coursenames.data.results) {
  console.log("Existing data courses");
  console.log(statenames.coursenames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching courses...");
  fetchCoursenames();
}

if (statenames && statenames.rolenames && statenames.rolenames.data && statenames.rolenames.data.results) {
  console.log("Existing data courses");
  console.log(statenames.coursenames.data.results);
} else {
  // Fetch data only if statenames.statenames.data.results doesn't exist or statenames is null
  console.log("Data not available, fetching courses...");
  fetchRolenames();
}
   
    
  },[])

  const fetchStatenames=()=>{
    const statenameid =  toast.loading("Fetching States...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getStateNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(statenameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(statenameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchCitynames=()=>{
    const citynameid =  toast.loading("Fetching Cities...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getCityNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(citynameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(citynameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchCoursenames=()=>{
    const coursenameid =  toast.loading("Fetching Courses...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getCourseNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(coursenameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(coursenameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

  const fetchRolenames=()=>{
    const rolenameid =  toast.loading("Fetching Roles...",{
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
   });
    dispatch(getRoleNames()).then(result=>
      {
         //console.log(result);
         if(result.payload.status===200){
          console.log(result.payload.data);
            toast.update(rolenameid, { render: "Successfull", type: "success", isLoading: false, autoClose: 2000,theme: "light"});
            //setEmail('')
            //setPassword('')
         //console.log("ready to navigate")
          //navigate('/dashboard')
         }
         else
         {
            if(result.payload.status === 401)
            {
              console.log(result.payload.data);
            toast.update(rolenameid, { render: result.payload.data, type: "error", isLoading: false, autoClose: 3000,theme: "colored"});
            }
         }
      })
  }

    return (
        <>
        <ToastContainer />
          <div className="position-relative" >
            <SubHeaderL valueFromRoute={valueFromRoute} valueFromSubroute={valueFromSubroute}/>
          </div>
          <div className="container-fluid m-auto" style={{width:'98%'}}>
            
           
             {/* <button
        onClick={() => {
          persistor.purge();
         
        }}
      >
        purge state
      </button> */}
          </div>
        </>
    )
}

export default Dashboard
